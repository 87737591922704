import React from "react"

const Footer = () => (
  <footer>
    <div className="footer-default">
      <div className="footer-container">
        <div className="logo-area">
          <img src="/images/logo_a_kakao_221012.png" alt="Stage Five" />
        </div>
        <div className="address-area">
          <div className="address right-indent-web">
            <div className="icon">
              <span className="sf-icon-pin"></span>
            </div>
            <div className="text">
              서울특별시 송파구 올림픽로35길 123 향군타워 6층 <br className="sf-mobile" />
              <label>Tel</label> <a href="tel:1688-0447">1688-0447</a> <label>Fax</label>{" "}
              <a href="tel:070-4772-6859">070-4772-6859</a>
            </div>
          </div>
          <div className="contact">
            <div className="icon">
              <span className="sf-icon-mail"></span>
            </div>
            <div className="text">
              <a href="mailto:contact@stagefive.com"> contact@stagefive.com</a>
            </div>
          </div>
          <div className="info">
            <div className="text">
              <span className="right-indent-web">대표이사 : 서상원</span>
              <br className="sf-mobile" />
              <span className="right-indent-web">사업자 등록번호 : 756-86-00029</span>
              <br className="sf-mobile" />
              <span className="copyright">Copyright © Stage Five Corp. All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
