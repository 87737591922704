import React, { Component } from "react"
import { Link } from "gatsby"

class Header extends Component {
  state = {
    menuOpen: false,
  }

  toggleHamburger = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  render() {
    return (
      <header>
        <nav className="navbar navbar-default">
          <div className="container-fluid">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar-menu"
                aria-expanded="false"
                onClick={() => this.toggleHamburger()}
              >
                <span className="sf-icon-menu-v2"></span>
              </button>
              <Link to="/" className="navbar-brand">
                <img src="/images/logo_a_kakao_221012.png" alt="STAGE FIVE" />
              </Link>
            </div>
            <div className={`navbar-collapse ${this.state.menuOpen ? "" : "collapse"}`} id="navbar-menu">
              <ul className="nav navbar-nav navbar-right">
                <li className="index">
                  <Link to="/" activeClassName="active" onClick={() => this.toggleHamburger()}>
                    ABOUT
                  </Link>
                </li>

                <li>
                  <a
                    href="https://stagefive.notion.site/STAGE-F1VE-ce4171c9a6ed419bb367e9dbcdfad9f9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    RECRUIT
                    <div className="new-icon">
                      <div className="text">N</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
